.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-full-screen {
  top: 6em;
  left: 0.5em;
}

.ol-control button {
  background-color: #000 !important;
  font-size: 1.5em;
  outline: none;
  cursor: pointer;
}
